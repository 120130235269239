@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {

  html,
  body {
    font-family: "Pretendard Variable", Pretendard, -apple-system,
      BlinkMacSystemFont, system-ui, Roboto, "Helvetica Neue", "Segoe UI",
      "Apple SD Gothic Neo", "Noto Sans KR", "Malgun Gothic",
      "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
  }

  *::-webkit-scrollbar {
    @apply w-3 bg-zinc-100 dark:bg-zinc-800;
  }
  *::-webkit-scrollbar-thumb {
    @apply bg-zinc-500 dark:bg-zinc-600 bg-opacity-50 rounded-full border-2 border-solid border-transparent;
    background-clip: padding-box;
  }
  *::-webkit-scrollbar-track {
    @apply bg-transparent;
  }

  .download-btn {
    @apply mt-12 flex items-center justify-center p-1 pr-6 bg-white rounded-full gap-3 mx-auto;
    box-shadow: 0px 20px 25px -5px rgba(255, 255, 255, 0.1), 0px 10px 10px -5px rgba(255, 255, 255, 0.04);
  }

}

@layer component {
  .ellipsis-3 {
    @apply !leading-6;
    display: -webkit-box;
    word-wrap: break-word;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .text {
    @apply text-zinc-900 dark:text-white;
  }

  .text-gray {
    @apply text-zinc-400 dark:text-zinc-500;
  }

  .swiper-pagination {
    @apply !text-right !pr-7 !pb-6;
  }

  .swiper-pagination-bullet {
    @apply !w-2 !rounded-full !h-2 !bg-white !mx-1.5;
  }

  .swiper-pagination-bullet-active {
    @apply !bg-blue-700;
    color: #fff;
    background: #007aff;
  }

  .left-glass {
    @apply flex flex-col z-10 w-[427px] absolute left-0 top-0 h-full overflow-auto;
    background: rgba(24, 24, 27, 0.9);
    backdrop-filter: blur(50px);
  }

  .logo-container {
    @apply p-11;
  }

  .logo {
    @apply shadow-md text-white select-none;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    letter-spacing: 0.52em;
  }
}
